<template>
  <div class="productItem" @click.self="openProduct(product.id)">
    <img
      @click.self="openProduct(product.id)"
      :src="
        productImage
          ? productImage
          : 'https://via.placeholder.com/430x230.png?text=No+image+added'
      "
    />
    <div class="productItemInfo">
      <h4 @click.self="openProduct(product.id)">{{ product.productName }}</h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  props: ['product'],
  data() {
    return {
      productImage: null,
      unitPrice: 0,
      kiloPrice: 0,
    }
  },
  async created() {
    try {
      if (this.product.units[0] != undefined) {
        this.kiloPrice = this.product.units[0].kiloPrice
        this.unitPrice = this.product.units[0].unitPrice
      }
    } catch (error) {}

    if (this.product.imageName) {
      let storageRef = firebase.storage().ref()
      let imageRef = storageRef.child(`images/${this.product.imageName}.jpg`)
      this.productImage = await imageRef.getDownloadURL()
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),
    checked: {
      get() {
        if (this.product.webshop == 1) {
          return true
        }
        return false
      },
      set(newValue) {
        let val
        if (newValue) {
          val = 1
        } else {
          val = 0
        }

        axios
          .post('products/updateWebshopStatus', {
            val,
            productId: this.product.id,
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  },
  methods: {
    updatePrice() {},
    capitalizeFirstLetter: (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    openProduct(productId) {
      this.$router.push({ name: 'Product', params: { productId } })
    },
  },
}
</script>

<style scoped lang="scss">
.productItem {
  margin: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s;
  transform: scale(1);
}

.productItem img {
  width: 100%;
  object-fit: cover;
  user-select: none;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(166, 166, 166, 1);
  -moz-box-shadow: 0px 0px 10px -5px rgba(166, 166, 166, 1);
  box-shadow: 0px 0px 10px -5px rgba(166, 166, 166, 1);
  height: 150px;
  border-radius: 5px;
}

.productItemInfo {
  padding: 10px 0px;
  transition: 0.3s;
  z-index: 10;
  top: 0;
}

.productItem:hover {
  transform: scale(1.02);
}

.productItemInfo h4 {
  font-size: 13pt;
  font-family: 'Avenir', sans-serif;
  margin: 0;
}

.productItemButtons {
  display: flex;
}

.productItemButton {
  color: #4a4a4a;
  font-size: 12pt;
  margin: auto;
}

.productItemIcon {
  margin: 4px auto;
}
</style>