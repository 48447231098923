<template>
  <tr>
    <td>{{ product.productName }}</td>
     <!-- <td>
      {{ product.units[0].timesChanged }}
    </td> -->
    <td>
      <input @change="updateValues" type="number" min="0" v-model="kiloPrice" />
    </td>
    <td>
      <input @change="updateValues" type="number" min="0" v-model="unitPrice" />
    </td>
  </tr>
</template>

<script>

import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: ["product"],
  data() {
    return {
      unitPrice: 0,
      kiloPrice: 0,
    };
  },
  async created() {
    try {
      if (this.product.units[0] != undefined) {
        this.kiloPrice = this.product.units[0].kiloPrice;
        this.unitPrice = this.product.units[0].unitPrice;
      }
    } catch (error) {}
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
    }),
  },
  methods: {
    updateValues() {
      axios
        .post("products/updateProductPrices", {
          kiloPrice: this.kiloPrice,
          unitPrice: this.unitPrice,
          productId: this.product.id
        })
    },
  },
};
</script>

<style scoped lang="scss">
td,
th {
  border-bottom: 1px solid #dddddd;
  font-family: "AvenirRound";
  font-size: 9pt;
  text-align: left;
  padding: 8px;
  background: white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 28px -1px rgba(214, 214, 214, 0.31);
  -moz-box-shadow: 0px 0px 28px -1px rgba(214, 214, 214, 0.31);
  box-shadow: 0px 0px 28px -1px rgba(214, 214, 214, 0.31);
}


input {
  border: none;
  background: none;
}

@media screen and (max-width: 700px) {
  td {
    width: 100px;
  }
  input {
    width: 40px;
  }
}
</style>