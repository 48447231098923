<template>
  <div>
    <div class="pageWrapper">
      <div class="topbar">
        <div style="display: flex">
          <H2>Products</H2>
        </div>
        <div style="display: flex">
          <MainButton
            :clickFunction="massChange"
            title="Bulk change prices"
            :buttonStyle="{
              marginLeft: '15px',
              fontSize: isMobile ? '6pt' : '10pt',
            }"
          />
          <MainButton
            :clickFunction="addProduct"
            title="Add product"
            styleClass="primary"
            :buttonStyle="{
              marginLeft: '15px',
              fontSize: isMobile ? '6pt' : '10pt',
            }"
          />
        </div>
      </div>
      <SearchInput placeholder="Search" v-model="searchString" />
      <br /><br />
      <Loader v-if="!products" :color="'#444'" />
      <div v-if="!massChangePrice" class="productsWrapper">
        <ProductItem
          :product="product"
          :key="product.id"
          v-for="product in products"
        />
      </div>
      <div v-if="massChangePrice" class="productRows">
        <table>
          <tr>
            <th>Product</th>
            <!-- <th>Times Changed</th> -->
            <th>Kilo (kr)</th>
            <th>Unit (kr)</th>
          </tr>
          <ProductRow
            :product="product"
            :key="product.id"
            v-for="product in productMostPriceChangedSort"
          />
        </table>
      </div>
    </div>
    <Loader v-if="loadingNewProducts" :height="0" :color="'#444'" />
    <br />
    <br />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

import Loader from '@/components/Loader'
import ProductItem from '@/components/products/allProducts/ProductItem'
import ProductRow from '@/components/products/allProducts/ProductRow'
import MainButton from '@/layout/MainButton'
import SearchInput from '@/layout/SearchInput'
import H2 from '@/layout/typography/H2'

export default {
  components: {
    Loader,
    ProductItem,
    MainButton,
    ProductRow,
    SearchInput,
    H2,
  },
  data() {
    return {
      displayPopup: false,
      products: null,
      searchString: '',
      loadingNewProducts: false,
      pages: null,
      currentPage: 1,
      massChangePrice: false,
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
    activeChangePrice1() {
      if (this.massChangePrice) {
        return { background: 'darkgreen', color: 'white' }
      }
    },
    activeChangePrice2() {
      if (this.massChangePrice) {
        return { background: 'green', color: 'white' }
      }
    },
    productMostPriceChangedSort() {
      return this.products.sort((a, b) => {
        if (a.units[0].timesChanged < b.units[0].timesChanged) {
          return 1
        } else {
          return -1
        }
      })
    },
  },
  watch: {
    searchString() {
      this.reset()
      this.getProducts()
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    addProduct() {
      axios
        .post('products/addProduct', {
          companyid: this.$store.state.selectedUser.companyId,
        })
        .then((res) => {
          this.$router.push({
            name: 'Product',
            params: { productId: res.data.newProductId },
          })
        })
    },
    massChange() {
      this.massChangePrice = !this.massChangePrice
    },
    reset() {
      this.products = null
      this.currentPage = 1
    },
    getProducts() {
      axios
        .post('products/getProductAmount', {
          searchString: this.searchString,
        })
        .then((res) => {
          this.pages = Math.ceil(res.data.amount / 30)
          axios
            .post('products/getProducts', {
              pages: this.pages,
              currentPage: this.currentPage,
              searchString: this.searchString,
            })
            .then((res) => {
              this.reset()
              this.products = res.data
            })
        })
    },
    loadNewProducts() {
      this.loadingNewProducts = true
      axios
        .post('products/getProducts', {
          pages: this.pages,
          currentPage: this.currentPage,
          searchString: this.searchString,
        })
        .then((res) => {
          this.products.push.apply(this.products, res.data)
          this.loadingNewProducts = false
        })
    },
    handleScroll() {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        this.loadingNewProducts == false &&
        this.currentPage + 1 <= this.pages
      ) {
        this.currentPage++
        this.loadNewProducts()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.settingsButton {
  cursor: pointer;
  background: #585b5e;
  position: relative;
  display: inline-flex;
  margin-bottom: 10px;
  border-radius: 3px;
  color: rgb(218, 218, 218);
  font-family: 'Spartan';
  font-weight: lighter;
  user-select: none;
}

.settingsButton div {
  padding: 5px 7px;
  border-radius: 3px 0 0 3px;
}

.settingsButton div:nth-child(1) {
  background: #404244;
}

.settings {
  position: relative;
  border-radius: 3px;
  display: grid;
  height: 0px;
  overflow: hidden;
  transition: 0.2s ease-in-out;
}
.settingsOption {
  display: inline-flex;
  margin: 5px 0;
  transition: 0.3s ease-in-out;
}

.settingsOption div {
  cursor: pointer;
  user-select: none;
  padding: 5px 7px;
}

.settingsOption div:nth-child(1) {
  border-radius: 3px 0 0 3px;
  color: rgb(223, 223, 223);
  width: 30px;
  text-align: center;
  background: rgb(83, 83, 83);
}
.settingsOption div:nth-child(2) {
  background: #ffffff;
  border-radius: 0 3px 3px 0;
}

.productsWrapper {
  background: white;
  border-radius: 10px;
  padding: 20px;
  display: grid;
  box-shadow: 0px 0px 5px -2px rgb(189 189 189);
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}
.productRows {
  width: 40%;
}

table {
  position: relative;
  border-collapse: separate;
  width: 100%;
  border-spacing: 5px;
}

table th {
  font-size: 12pt;
  font-family: 'AvenirRound';
}

@media screen and (max-width: 700px) {
  .productsWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  .productRows {
    width: 100%;
  }
  .topbar {
    margin-bottom: 20px;
  }
}
</style>